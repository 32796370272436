<template>
  <section class="resposta-multipla-escolha">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <p class="text-size-90 mt-1" v-if="imagemPergunta">
          <v-img max-height="300" max-width="400" :src="imagemPergunta"></v-img>
        </p>
      </v-col>

      <v-col cols="12" md="12" sm="12">
        <v-textarea
          outlined
          label="Descrição"
          v-model="respostaQuestionario"
          :name="`${indexPergunta}`"
          data-vv-scope="respostaQuestionario"
          :error="errors.collect(`respostaQuestionario`).length ? true : false"
          v-validate="'required'"
          persistent-hint
          @change="defineRespostaAlternativa()"
        ></v-textarea>
        <span
          v-show="errors.has(`respostaQuestionario`)"
          style="color: red"
          v-html="'Marque ao menos uma opção!'"
        >
        </span>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "resposta-text-escolha",
  inject: ["$validator"],
  props: [
    "indexPergunta",
    "textoPergunta",
    "imagemPergunta",
    "uuidPergunta",
    "categoria",
    "subcategoria",
    "codeFormulario",
  ],
  components: {},
  data() {
    return {
      respostaQuestionario: "",
    };
  },
  methods: {
    defineRespostaAlternativa() {
      // Prepara o objeto da pergunta com suas respostas para salvar no banco
      const data = {
        indexPergunta: this.indexPergunta,
        uuid_pergunta: this.uuidPergunta,
        descricao: this.respostaQuestionario,
        uuid_escala_itens: "",
        uuid_alternativa: "",
        code_formulario: this.codeFormulario,
        opcao: [],
      };
      // Atualiza ou Adiciona resposta dentro do array respostas
      if (this.respostas.some((r) => r.uuid_pergunta === this.uuidPergunta)) {
        const index = this.respostas.findIndex(
          (r) => r.uuid_pergunta === this.uuidPergunta
        );
        if (index !== -1) {
          this.respostas.splice(index, 1, data);
        }
      } else {
        this.respostas.push(data);
      }
      // Atualiza data de resposta local do componente pai
      this.$emit("atualiza-resposta-local", data, "text");
    },
    /**
     * [validarArrayRespostas description]
     * @description Pecorre o array de respostas salvas no vuex
     * @author Johnny Santos <johnny.santos@sociisrh.com.br>
     */
    validarArrayRespostas() {
      if (this.respostas == undefined || this.respostas.length === 0)
        return null;
      this.verificaPerguntas();
    },
    /**
     * [verificaPerguntas description]
     *
     * @description Verifica se o index da pergunta atual está dentro do array de resposta e se o array de resposta atual está vazio, caso estiver
     * o array de resposta recebe as opçoes da pergunta atual, se não ele realiza uma verificação no array de respostas atuais.
     * @author Johnny Santos <johnny.santos@sociisrh.com.br>
     */
    verificaPerguntas() {
      if (this.respostas.some((r) => r.uuid_pergunta === this.uuidPergunta)) {
        const result = this.respostas.filter(
          (el) => el.uuid_pergunta === this.uuidPergunta
        );

        if (result.length > 0) {
          this.respostaQuestionario =
            result[0].descricao !== "" ? result[0].descricao : result[0].opcao;
          this.defineRespostaAlternativa();
        }
      }
    },
  },
  computed: {
    respostas: {
      get() {
        return this.$store.getters["pesquisas/getRespostas"];
      },
      set(value) {
        this.$store.dispatch("pesquisas/setRespostas", value);
      },
    },
  },
  beforeMount() {
    this.validarArrayRespostas();
  },
  /**
   * [destroyed description]
   * @description Responsavel por limpar o array de perguntas atuais após o componente ser removido do life cycle
   * @author Johnny Santos <johnny.santos@sociisrh.com.br>
   */
  destroyed() {
    this.respostaQuestionario = [];
  },
  watch: {
    indexPergunta: function () {
      // Verificar se existe um objeto resposta em respostas com mesmo indice da pergunta atual
      if (this.respostas.some((r) => r.uuid_pergunta === this.uuidPergunta)) {
        // Se existir, respostaQuestionario recebe esse objeto
        const result = this.respostas.filter(
          (el) => el.uuid_pergunta === this.uuidPergunta
        );
        if (result.length > 0) {
          this.respostaQuestionario =
            result[0].descricao !== "" ? result[0].descricao : result[0].opcao;
        }
      } else {
        this.respostaQuestionario = [];
      }
    },
  },
};
</script>

<style lang="scss"></style>
